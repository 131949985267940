import { NextSeo } from 'next-seo';
import Head from 'next/head';
// import Image from 'next/image';
import { useEffect, useState } from 'react';
import Link from 'next/link';

// import TMNLogo from '../../public/images/tmn-logo-white.png';

const IndexPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    // const [formSubmission, setFormSubmission] = useState<'pending' |'submitting'| 'error'| 'success'>('pending');

    useEffect(() => {
        const tm = setTimeout(() => setIsLoading(false), 150);
        return () => {
            clearTimeout(tm);
        };
    }, []);

    return (
        <>
            <NextSeo
                title="Transmarine Navigation - Marine Survey Professionals"
                description="Transmarine Sri Lanka. Bunker Survey, Cargo Survey, Draught Survey, AGM Inspection, Bunker Quantity Survey, Container Damage Survey, Cargo Damage Survey, Compass Repair, Compass Calibration, Damage Assessment, Discharge Supervision Survey, Gas Free Certification and Investigation."
                canonical="https://www.transmarine.lk/"
                openGraph={{
                    url: 'https://www.transmarine.lk/',
                    title: 'Transmarine Navigation - Marine Surveyors',
                    description:
                        'Transmarine Sri Lanka. Bunker Survey, Cargo Survey, Draught Survey, AGM Inspection, Bunker Quantity Survey, Container Damage Survey, Cargo Damage Survey, Compass Repair, Compass Calibration, Damage Assessment, Discharge Supervision Survey, Gas Free Certification and Investigation.',
                    images: [
                        {
                            url: 'https://www.transmarine.lk/images/dockyard_1.jpg',
                            alt: 'Marine survey and investigation',
                        },
                        {
                            url: 'https://www.transmarine.lk/images/dockyard_2.jpg',
                            alt: 'Ship inspection',
                        },
                    ],
                    site_name: 'Transmarine Navigation',
                }}
            />
            <div className="homepage">
                <Head>
                    <title>Transmarine Navigation - Marine Survey Professionals</title>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
                    <link rel="stylesheet" href="/assets/css/main.css" />
                    <noscript>
                        <link rel="stylesheet" href="/assets/css/noscript.css" />
                    </noscript>
                    <link rel="apple-touch-icon" sizes="57x57" href="/images/icon/apple-icon-57x57.png" />
                    <link rel="apple-touch-icon" sizes="60x60" href="/images/icon/apple-icon-60x60.png" />
                    <link rel="apple-touch-icon" sizes="72x72" href="/images/icon/apple-icon-72x72.png" />
                    <link rel="apple-touch-icon" sizes="76x76" href="/images/icon/apple-icon-76x76.png" />
                    <link rel="apple-touch-icon" sizes="114x114" href="/images/icon/apple-icon-114x114.png" />
                    <link rel="apple-touch-icon" sizes="120x120" href="/images/icon/apple-icon-120x120.png" />
                    <link rel="apple-touch-icon" sizes="144x144" href="/images/icon/apple-icon-144x144.png" />
                    <link rel="apple-touch-icon" sizes="152x152" href="/images/icon/apple-icon-152x152.png" />
                    <link rel="apple-touch-icon" sizes="180x180" href="/images/icon/apple-icon-180x180.png" />
                    <link rel="icon" type="image/png" sizes="192x192" href="/images/icon/android-icon-192x192.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/images/icon/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="96x96" href="/images/icon/favicon-96x96.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/images/icon/favicon-16x16.png" />
                    <link rel="manifest" href="/images/icon/manifest.json" />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
                    <meta name="theme-color" content="#ffffff" />
                </Head>
                <div className={isLoading ? 'is-preload' : ''}>
                    {/* Wrapper */}
                    <div id="wrapper">
                        {/* Header */}
                        <header id="header">
                            <div className="logo">{/* <Image src={TMNLogo} alt="Transmarine" /> */}</div>
                            <div className="content">
                                <div className="inner">
                                    <h1>
                                        Transmarine <span>Navigation</span>
                                    </h1>

                                    <p style={{ lineHeight: 1.3, textAlign: 'center' }}>
                                        Independent surveyors &amp; consultants. &nbsp; Offering reliable and efficeint
                                        professional services for the marine field.
                                    </p>
                                </div>
                            </div>
                            {/* <nav>
                                <ul>
                                    <li>
                                        <a href="#intro">Intro</a>
                                    </li>
                                    <li>
                                        <a href="#work">Work</a>
                                    </li>
                                    <li>
                                        <a href="#about">About</a>
                                    </li>
                                    <li>
                                        <a href="#contact">Contact</a>
                                    </li>
                                </ul>
                            </nav> */}
                        </header>

                        <Link href="/login" passHref>
                            <span
                                style={{ color: 'white', position: 'absolute', top: 10, right: 15, cursor: 'pointer' }}>
                                Login
                            </span>
                        </Link>

                        {/* Main */}
                        <div id="main">
                            {/* Intro */}
                            <article id="intro">
                                <h2 className="major">Intro</h2>
                                <span className="image main">
                                    <img src="images/dockyard_1.jpg" alt="" />
                                </span>
                                <p>
                                    Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin aliquam facilisis
                                    ante interdum congue. Integer mollis, nisl amet convallis, porttitor magna
                                    ullamcorper, amet egestas mauris. Ut magna finibus nisi nec lacinia. Nam maximus
                                    erat id euismod egestas. By the way, check out my <a href="#work">awesome work</a>.
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus rutrum
                                    facilisis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
                                    inceptos himenaeos. Etiam tristique libero eu nibh porttitor fermentum. Nullam
                                    venenatis erat id vehicula viverra. Nunc ultrices eros ut ultricies condimentum.
                                    Mauris risus lacus, blandit sit amet venenatis non, bibendum vitae dolor. Nunc lorem
                                    mauris, fringilla in aliquam at, euismod in lectus. Pellentesque habitant morbi
                                    tristique senectus et netus et malesuada fames ac turpis egestas. In non lorem sit
                                    amet elit placerat maximus. Pellentesque aliquam maximus risus, vel sed vehicula.
                                </p>
                            </article>

                            {/* Work */}
                            <article id="work">
                                <h2 className="major">Work</h2>
                                <span className="image main">
                                    <img src="images/dockyard_2.jpg" alt="" />
                                </span>
                                <p>
                                    Adipiscing magna sed dolor elit. Praesent eleifend dignissim arcu, at eleifend
                                    sapien imperdiet ac. Aliquam erat volutpat. Praesent urna nisi, fringila lorem et
                                    vehicula lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.
                                </p>
                                <p>
                                    Nullam et orci eu lorem consequat tincidunt vivamus et sagittis libero. Mauris
                                    aliquet magna magna sed nunc rhoncus pharetra. Pellentesque condimentum sem. In
                                    efficitur ligula tate urna. Maecenas laoreet massa vel lacinia pellentesque lorem
                                    ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
                                    libero. Mauris aliquet magna magna sed nunc rhoncus amet feugiat tempus.
                                </p>
                            </article>

                            {/* About */}
                            <article id="about">
                                <h2 className="major">About</h2>
                                <span className="image main">
                                    <img src="images/pic03.jpg" alt="" />
                                </span>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur et adipiscing elit. Praesent eleifend
                                    dignissim arcu, at eleifend sapien imperdiet ac. Aliquam erat volutpat. Praesent
                                    urna nisi, fringila lorem et vehicula lacinia quam. Integer sollicitudin mauris nec
                                    lorem luctus ultrices. Aliquam libero et malesuada fames ac ante ipsum primis in
                                    faucibus. Cras viverra ligula sit amet ex mollis mattis lorem ipsum dolor sit amet.
                                </p>
                            </article>

                            {/* Contact */}
                            <article id="contact">
                                <h2 className="major">Contact</h2>
                                <form
                                    action="#"
                                    onSubmit={(ev) => {
                                        ev.preventDefault();
                                    }}>
                                    <div className="fields">
                                        <div className="field half">
                                            {/* eslint-disable jsx-a11y/label-has-associated-control */}
                                            <label htmlFor="name">Name</label>
                                            <input type="text" name="name" id="name" />
                                        </div>
                                        <div className="field half">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" name="email" id="email" />
                                        </div>
                                        <div className="field">
                                            <label htmlFor="message">Message</label>
                                            <textarea name="message" id="message" rows={4} />
                                        </div>
                                        {/* eslint-enable jsx-a11y/label-has-associated-control */}
                                    </div>
                                    <ul className="actions">
                                        <li>
                                            <input type="submit" value="Send Message" className="primary" />
                                        </li>
                                        <li>
                                            <input type="reset" value="Reset" />
                                        </li>
                                    </ul>
                                </form>
                                {/* <ul className="icons">
                <li>
                  <a href="#" className="icon brands fa-twitter">
                    <span className="label">
                      Twitter
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#" className="icon brands fa-facebook-f">
                    <span className="label">
                      Facebook
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#" className="icon brands fa-instagram">
                    <span className="label">
                      Instagram
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#" className="icon brands fa-github">
                    <span className="label">
                      GitHub
                    </span>
                  </a>
                </li>
              </ul> */}
                            </article>
                        </div>

                        {/* Footer */}
                        <footer id="footer">
                            <p className="copyright">
                                &copy; Transmarine. Web: <a href="https://zynch.com">zynch</a>.
                            </p>
                        </footer>
                    </div>

                    {/* BG */}
                    <div id="bg" />
                </div>

                {/* Scripts */}
                <script src="assets/js/jquery.min.js" />
                <script src="assets/js/browser.min.js" />
                <script src="assets/js/breakpoints.min.js" />
                <script src="assets/js/util.js" />
                <script src="assets/js/main.js" />
            </div>
        </>
    );
};

export default IndexPage;
